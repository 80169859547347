import React from 'react'

const buildHistories = histories => (
    histories.map(history => (
        <li className="timeline-block">
          <div className="timeline-date">
              <span>{history.year}</span>
          </div>

          <div className="timeline-icon">
              <span className="dot-badge"></span>
          </div>

          <div className="timeline-content">
              <div className="row align-items-center">
                  <div className="col-lg-7 col-md-12">
                      <div className="content">
                          <h3>{history.title}</h3>
                          <p>{history.description}</p>
                      </div>
                  </div>

                    <div className="col-lg-5 col-md-12">
                        <div className="image" style={{textAlign: 'center'}}>
                            <img src={history.img} alt={history.altText} style={{maxWidth: '200px'}} />
                        </div>
                    </div>
                </div>
            </div>
        </li>
    ))
);

const OurHistory = (props) => {
    const { histories } = props;
    return (
        <section className="history-area ptb-100 bg-fafafb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">The Last Fifteen Years</span>
                    <h2>My Adventures in Technology</h2>
                </div>

                <ol className="timeline history-timeline">
                    {buildHistories(histories)}
                </ol>
            </div>
        </section>
    )
}

export default OurHistory
