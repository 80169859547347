import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronsRight } from "@fortawesome/pro-duotone-svg-icons";

const buildCallouts = callouts => (
    callouts.map(callout => (
        <li>
            <h3>{callout.title}</h3>
            <p>{callout.subTitle}</p>
            {/* <img src={callout.icon} alt="banner" /> */}
        </li>
    ))
);

const AboutUsContent = (props) => {
    const {
        aboutImage,
        title,
        descriptionOne,
        callouts,
        descriptionTwo,
        buttonTitle,
        buttonLink,
        shape
    } = props;
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="about page alien" style={{maxHeight: '350px'}}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <h2>{title}</h2>
                                <p>{descriptionOne}</p>
                                <ul className="features-list">
                                  {buildCallouts(callouts)}
                                </ul>
                                <p>{descriptionTwo}</p>
                                <Link to={buttonLink} className="default-btn">
                                  <FontAwesomeIcon icon={faChevronsRight} className="flaticon-right" style={{width: '20px', height: '20px' }}/>
                                  {buttonTitle}<span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="circle-shape1">
                <img src={shape} alt="banner" />
            </div>

            <div className="circle-shape1">
                <img src={shape} alt="banner" />
            </div> */}
        </section>
    )
}

export default AboutUsContent;
