import React from 'react'

const buildPartners = partners => (
    partners.map((partner, index) => (
        <div className="col-lg-3 col-6">

                <div className="single-partner-item">
                    <a href={partner.url} target="_blank" rel="noreferrer">
                      <img src={partner.img} alt={partner.altTitle} style={{height: partner.height}} />
                    </a>
                </div>
        </div>
    ))
);

const Partner = (props) => {
    const { partners } = props;
    return (
        <div className="partner-area pt-100 pb-70 bg-fafafb">
            <div className="container">
            <div className="section-title">
                    <span className="sub-title">Some brands I have worked with</span>
                </div>
                <div className="row align-items-center">
                    {buildPartners(partners)}
                </div>
            </div>
        </div>
    )
}

export default Partner
