import aboutImage from '../../assets/images/alien-breaks-through.svg'
// partner images
import partner2 from '../../assets/images/brand-logos/kandji.svg'
import partner3 from '../../assets/images/brand-logos/wm.svg'
import partner4 from '../../assets/images/brand-logos/driz.svg'
import partner5 from '../../assets/images/brand-logos/volusion.svg'
// histories

import history2 from '../../assets/images/fox-top.webp'
import history1 from '../../assets/images/fox-bottom.webp'
import history4 from '../../assets/images/bear-top.webp'
import history3 from '../../assets/images/bear-bottom.webp'


export const pageHeader = {
  pageTitle: 'About Me',
  homePageText: 'Home',
  homePageUrl: '/',
  activePageText: 'About Me',
};

export const banner = {
  aboutImage: aboutImage,
  title: 'Engineering Leader, Mentor, Builder, Cook',
  descriptionOne: 'I am an engineering leader with a strong preference for leading Cloud Infrastructure teams and empowering DevOps transformations. My mission is to combine software development principles with modern infrastructure to allow everyone to ship software quickly and securely.',
  callouts: [
    {
      title: '20 Years',
      subTitle: 'In the Industry',
    },
    {
      title: '100+',
      subTitle: 'Services Supported',
    },
    {
      title: '34',
      subTitle: 'Releases Per Week',
    },
    {
      title: '3',
      subTitle: 'DevOps Transformations',
    },
  ],
  descriptionTwo: 'I currently lead the Infrastructure, SRE, and Quality engineering teams at Kandji. The teams are comprised of infrastructure experts, SREs, and software developers that create and manage the infrastructure, CI/CD, automated testing, monitoring, and observability for the Kandji ecosystem.',
  buttonTitle: 'Contact Me',
  buttonLink: '/contact',
};

export const histories = [
  {
    year: '2006',
    title: 'Freelance Developer',
    description: 'I returned to tech by working freelance for a few years on a series of web apps, including an early youtube competitor, a suite of marketing tools, and an early social dashboard. This work helped me grow accustomed to working on a software team where members lived remote and worked on different schedules.',
    img: history1,
    altText: 'teleporting fox bottom',
  },
  {
    year: '2008',
    title: 'Hosting & Ecommerce',
    description: 'Early in my career, I was lucky enough to work in hosting and e-commerce. Working with a large variety of clients in all different industries provides a great learning experience. You also have a lot of opportunities to hone your troubleshooting skills as you work to support code from so many disparate sources.',
    img: history2,
    altText: 'teleporting fox top',
  },
  {
    year: '2011',
    title: 'Small SAAS Startups',
    description: 'My time working in small SAAS startups taught me so much about being an engineering leader and what it means to support a large technology scale. I learned how important it is to make sure you are building the right thing and iteratively delivering value. During this time, I began to focus on repeatable infrastructure, ci/cd, and developer experience, a topic that was starting to gain popularity.',
    img: history3,
    altText: 'teleporting bear bottom',
  },
  {
    year: '2018',
    title: 'High Growth SAAS Startups',
    description: 'My time in high-growth SAAS companies helped solidify my passion for DevOps and Cloud Engineering.  I have been fortunate to work at several forward-thinking companies that allowed me and my teams to lead them through a DevOps transformation.  These successful transformations have allowed me the opportunity to specialize in building the teams and processes that empower the growth of the entire engineering department.  ',
    img: history4,
    altText: 'teleporting bear top',
  },
];

export const partners = [
  {
    img: partner2,
    altTitle: 'Kandji',
    height: '30px',
    url: '//kandji.io',
  },
  {
    img: partner3,
    altTitle: 'Weedmaps',
    height: '50px',
    url: '//weedmaps.com',
  },
  {
    img: partner4,
    altTitle: 'Drizly',
    height: '40px',
    url: '//drizly.com',
  },
  {
    img: partner5,
    altTitle: 'Volusion',
    height: '30px',
    url: '//volusion.com',
  },
];
