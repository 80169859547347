import React from 'react'
import Layout from "../components/App/Layout"
import SEO from '../components/App/SEO'
import NavbarDynamic from "../components/App/NavbarDynamic"
import PageBanner from '../components/Common/PageBanner'
import FooterMinimal from "../components/App/FooterMinimal"
import AboutUsContent from '../components/AboutMe/AboutUsContent'
import OurHistory from '../components/AboutMe/OurHistory'
import Partner from '../components/AboutMe/Partner'
import { banner, pageHeader, partners, histories } from '../data/page-data/aboutData';

const headerInfo = {
    title: 'About Me | BryanLokey.com',
    description: 'Engineering Leader, Mentor, Builder, Cook',
    keywords: 'engineering leader,mentor,devops',
    canonical: 'https://bryanlokey.com/about/',
    ogImage: banner.aboutImage,
};

const AboutMe = () => {
    return (
        <div>
          <SEO {...headerInfo}/>
          <Layout>
            <NavbarDynamic />
            <PageBanner {...pageHeader} />
            <AboutUsContent {...banner} />
            <OurHistory histories={histories} />
            <Partner partners={partners} />
            <FooterMinimal />
          </Layout>
        </div>
    );
}

export default AboutMe;
